import { Summary } from "lib/service/client/FrontTopPageService";
import React, { FC, HTMLAttributes, useMemo } from "react";
import SsHeadline from "../layout/SsHeadline";
import { TopPageProject } from "interfaces/front/page/top";
import styled from "styled-components";
import ProjectCard from "./ProjectCard";

export type Props = HTMLAttributes<HTMLElement> & { projects: TopPageProject[]; summaries?: Summary[] };
const ShoppingProjectsSection: FC<Props> = ({ projects, summaries, ...props }) => {
  const projectSummaryPairs = useMemo(() => {
    return projects.map((project) => {
      const summary = summaries?.find((summary) => summary.project_id === Number(project.id));
      return { project, summary };
    });
  }, [projects, summaries]);
  return projects.length > 0 ? (
    <section {...props}>
      <SsHeadline color="white" label={<Logo src="img/shopping_logo.png" />} />
      <CardList>
        {projectSummaryPairs.map((pair, index) => (
          <ProjectCard key={index} project={pair.project} summary={pair.summary} isShopping={true} />
        ))}
      </CardList>
    </section>
  ) : (
    <></>
  );
};
export default ShoppingProjectsSection;

const Logo = styled.img`
  @media screen and (max-width: 960px) {
    height: 21px;
  }
  @media screen and (min-width: 961px) {
    height: 36px;
  }
`;
const CardList = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  @media screen and (min-width: 961px) {
    &:after {
      content: "";
      display: block;
      width: calc((100% - 30px) / 3);
    }
  }
`;
